<template>
  <div>
    <form-group
      name="kinds"
      :label="t('validation.attributes.kind')"
      type="select"
      margins="mb-2"
      :form-control-props="{
        mode: 'tags',
        searchable: false,
        options: excursionKindFilterOptions,
      }"
      @changed="(value) => handleFilterChange('kinds', value)"
    >
      <template #help-button>
        <ul>
          <li class="mb-5">
            <p class="font-bold">
              {{ t('enums.App\\Enums\\Excursion\\KindEnum.customized') }}
            </p>
            {{ t('form.hints.excursion.kind.pro.customized') }}
          </li>
          <li class="mb-5">
            <p class="font-bold">
              {{ t('enums.App\\Enums\\Excursion\\KindEnum.root') }}
            </p>
            {{ t('form.hints.excursion.kind.pro.root') }}
          </li>
          <li>
            <p class="font-bold">
              {{ t('enums.App\\Enums\\Excursion\\KindEnum.ugozer') }}
            </p>
            {{ t('form.hints.excursion.kind.pro.ugozer') }}
          </li>
        </ul>
      </template>
    </form-group>

    <h3 class="text-lg font-bold mb-4 mt-8">
      {{ capitalize(t('form.labels.geoloc_setting.distance_filter')) }}
    </h3>

    <form-group
      name="geoloc"
      :label="t('form.labels.points_of_interest_filters.geoloc')"
      margins="mb-0"
      type="select"
      :submittable="false"
      :form-control-props="{
        clearable: true,
        filterable: false,
        options: handleGeolocFilterSearch,
        minChars: 3,
        searchHeaderText: t('form.hints.geoloc_setting.filter_header'),
      }"
      @changed="handleGeolocFilterChange"
    >
      <template #control-vue-multiselect-option="option">
        <div>
          <template v-if="option.icon">
            <font-awesome-icon :icon="option.icon" />
            &nbsp;
          </template>

          <strong>
            {{ option.label }}
          </strong>

          <template v-if="option.subLabel">
            <br>
            <em class="text-sm">
              {{ option.subLabel }}
            </em>
          </template>
        </div>
      </template>
    </form-group>

    <form-group
      name="latitude"
      type="hidden"
      @changed="(value) => handleFilterChange('latitude', value)"
    />

    <form-group
      name="longitude"
      type="hidden"
      @changed="(value) => handleFilterChange('longitude', value)"
    />

    <form-group
      v-if="geolocFilterPresent"
      name="limit_to_a_radius"
      :submittable="false"
      :label="t('form.labels.geoloc_setting.limit_to_a_radius')"
      :hint="t('form.hints.excursions_filters.radius')"
      type="switch"
      @changed="(value) => handleFilterChange('limit_to_a_radius', value)"
    />

    <form-group
      v-else
      :initial-value="false"
      name="limit_to_a_radius"
      :submittable="false"
      type="hidden"
      @changed="(value) => handleFilterChange('limit_to_a_radius', value)"
    />

    <form-group
      v-if="limitToRadiusValue"
      class="top-3 relative"
      name="radius"
      :label="false"
      :initial-value="store.state.filters.pointsOfInterest.radius || 10"
      type="slider"
      rules="min.numeric:1|required"
      :form-control-props="{
        min: 1,
        max: maxRadius,
        formatSliderTooltips: formatSliderTooltips,
      }"
      @changed="(value) => handleFilterChange('radius', value)"
    />

    <form-group
      v-else
      name="radius"
      :initial-value="null"
      type="hidden"
      @changed="(value) => handleFilterChange('radius', value)"
    />

    <form-group
      v-if="limitToRadiusValue"
      name="measurement_system"
      :label="t('form.labels.geoloc_setting.measure_unit')"
      type="radio"
      :form-control-props="{
        options: measureUnitOptions,
      }"
      @changed="(value) => handleFilterChange('measurement_system', value)"
    />

    <div class="flex justify-center mt-4">
      <app-button
        feature="reset"
        :label="t('common.actions.reset')"
        class="mr-5"
        padding="py-2 px-6"
        emphasis="low"
        font-color-classes="text-theme-900"
        @click="handleResetFilters"
      />

      <app-button
        feature="apply"
        :label="t('common.actions.apply')"
        padding="py-2 px-6"
        @click="handleFiltersApplied"
      />
    </div>
  </div>
</template>

<script setup>
import { inject, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { isNil, capitalize } from 'lodash'
import { useStore } from 'vuex'

import AppButton from '@shared/components/ui/AppButton.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import useFilters from '@shared/hooks/filters'
import useGeolocFilters from '@extranet/hooks/geolocFilters'

const emits = defineEmits([
  'filters-applied',
])

const { t } = useI18n()
const store = useStore()

const form = inject('excursionsIndexFiltersForm')

const {
  maxRadius,
  handleGeolocFilterChange: handleGeolocFilterChangeHook,
  handleGeolocFilterSearch,
  formatSliderTooltips,
} = useGeolocFilters(form.value)

const {
  handleResetFilters,
  handleFiltersApplied,
  handleFilterChange,
} = useFilters('excursions', form.value, { emits })

const {
  excursionKindFilterOptions,
  measureUnitOptions,
} = useSelectOptions()

const limitToRadiusValue = computed(() => (
  form.value.values.limit_to_a_radius
))

const geolocFilterPresent = computed(() => (
  !isNil(form.value.values.geoloc)
))

function handleGeolocFilterChange(value) {
  handleFilterChange('geoloc', value)
  handleGeolocFilterChangeHook(value)
}
</script>
