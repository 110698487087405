<template>
  <div class="flex flex-col max-w-md mx-auto min-w-fit">
    <app-button
      :label="t('excursions.form.select_from_points_of_interest')"
      class="mb-5"
      @click="emits('modeSelected', 'from_points_of_interest')"
    />

    <app-button
      :label="t('excursions.form.from_existing_excursions')"
      emphasis="medium"
      class="mb-5"
      @click="emits('modeSelected', 'from_existing_excursions')"
    />

    <app-button
      :label="t('excursions.form.create_new_point_of_interest')"
      emphasis="medium"
      class="mb-5"
      @click="emits('modeSelected', 'create_point_of_interest')"
    />
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n'
import AppButton from '@shared/components/ui/AppButton.vue'

defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const emits = defineEmits([
  'modeSelected',
])

const { t } = useI18n()
</script>
