<template>
  <teleport-wrapper to="#breadcrumbs">
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <teleport-wrapper to="#mainTitle">
    {{ t('breadcrumbs.excursions.create') }}
  </teleport-wrapper>

  <excursion-form
    v-if="!store.state.spinner.active"
    :resource="excursion"
    :additional-errors="excursionFormErrors"
    :submitting="excursionFormSubmitting"
    :force-step="forceStep"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { compact, set } from 'lodash'
import { useRoute, useRouter } from 'vue-router'

import {
  fetchPointsOfInterest,
} from '@shared/http/api'
import breadcrumbExcursion from '@extranet/breadcrumbs/excursion'
import useExcursionForm from '@shared/hooks/resources/form/excursion'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'
import ExcursionForm from '@extranet/components/resources/excursion/main_form/ExcursionForm.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const {
  resource: excursion,
  errors: excursionFormErrors,
  submitting: excursionFormSubmitting,
  handleSubmit,
} = useExcursionForm({
  onSubmitSuccess,
})

// Retrieve step from route query, or default to 1
const forceStep = ref(
  parseInt(route.query.step, 10)
  || 1,
)

function onSubmitSuccess(response) {
  router.push({
    name: 'show-excursions',
    params: { id: response.data.data?.id },
  })

  store.commit(
    'flashes/ADD_FLASH',
    {
      message: t('excursions.store.success'),
      type: 'success',
    },
  )
}

function fetchPois() {
  const points_of_interest_ids = compact([
    store.state.auth.user.relationships.point_of_interest?.id, // Get POI from auth user relation
    route.query.point_of_interest_id, // Get POI id from route query params
  ])

  if (points_of_interest_ids.length > 0) {
    store.commit('spinner/ENABLE')

    fetchPointsOfInterest({
      'filter[id]': points_of_interest_ids,
      'mode': 'index',
    })
      .then((response) => {
        // prefill relationships with poi resources
        set(
          excursion.value,
          'relationships.excursions_points_of_interest',
          response.data.data?.map((poi, index) => ({
            relationships: {
              point_of_interest: poi,
            },
            attributes: {
              position: index,
            },
          })) ?? [],
        )
      })
      .finally(() => {
        store.commit('spinner/DISABLE')
      })
  }
}

// ---------- BREADCRUMBS ----------

const { create } = breadcrumbExcursion()

const breadcrumbs = computed(() => (
  create()
))

// ---------- LIFECYCLES ----------

onMounted(() => {
  fetchPois()
})
</script>
