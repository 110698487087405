<template>
  <div>
    <form-group
      name="excursions_ids"
      type="hidden"
      @changed="(value) => handleFilterChange('excursions_ids', value)"
    />

    <existing-excursions-index
      :edited-excursion="editedExcursion"
      :selected-excursion-id="selectedExcursionId"
      @excursion-selected="handleExcursionSelected"
    />
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'

import FormGroup from '@shared/components/form/FormGroup.vue'
import ExistingExcursionsIndex from '@extranet/components/resources/excursion/main_form/steps/columns/filters/existing_excursions/ExistingExcursionsIndex.vue'
import useFilters from '@shared/hooks/filters'

const emits = defineEmits([
  'filters-applied',
])

defineProps({
  // Resource related to the main form
  editedExcursion: {
    type: Object,
    default: () => ({}),
  },
})

const form = inject('pointsOfInterestIndexFiltersForm')

const {
  handleFiltersApplied,
  handleFilterChange,
} = useFilters('pointsOfInterest', form.value, { emits })

// Retrieve which excursion is currently selected
const selectedExcursionId = computed(() => (
  form.value.values.excursions_ids?.[0]
))

function handleExcursionSelected(id) {
  // Select new excursion id, or unselect if already selected
  const value = id === selectedExcursionId.value ? null : [id]
  form.value.setFieldValue('excursions_ids', value)

  // Directly apply this new filter
  handleFiltersApplied()
}
</script>
