<template>
  <div
    v-show="formGroupProps?.type !== 'hidden'"
    class="flex items-start"
  >
    <!-- Locale selector margin equals form-label's height, so it's align with form group's control -->
    <app-locale-selector
      :class="`w-28 mr-3 ${invalid ? 'text-red-500' : ''} mt-6`"
      :options="dynamicLocalesOptions"
      :initial-value="initialLocale"
      :invalid="invalid"
      @changed="handleLocaleChange"
    />

    <form-group
      v-for="(locale, i) in dynamicLocales"
      v-show="selectedLocale === locale"
      :key="i"
      v-bind="formGroupProps"
      :name="nameWithLocale(locale)"
      :rules="rulesToUse(locale)"
      :initial-value="((...parameters) => formGroupPropByLocale(locale, 'initialValue', ...parameters))()"
      class="flex-1"
      :form-control-props="{
        ...formGroupProps.formControlProps,
        options: (...parameters) => formControlPropByLocale(locale, 'options', ...parameters)
      }"
      :form-label-props="{
        ...formGroupProps.formLabelProps,
      }"
      v-on="formGroupEvents"
    >
      <template
        v-for="(slot, slotName) in $slots"
        #[slotName]="slotProps"
      >
        <slot
          :name="slotName"
          v-bind="slotProps"
        />
      </template>
    </form-group>
  </div>
</template>

<script setup>
import { computed, ref, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { get, isNil } from 'lodash'

import useLocale from '@shared/hooks/locale'
import AppLocaleSelector from '@shared/components/ui/AppLocaleSelector.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const props = defineProps({
  // Required locale keys
  requiredLocales: {
    type: Array,
    default: () => ([]),
  },
  // form-group component's props
  formGroupProps: {
    type: Object,
    default: () => ({}),
  },
  // form-group component's events handler
  formGroupEvents: {
    type: Object,
    default: () => ({}),
  },
})

const {
  locale: initialLocale,
} = useI18n()
const form = inject('form', ref({}))

const {
  dynamicLocales,
  dynamicLocalesOptions,
} = useLocale()

const selectedLocale = ref(initialLocale.value)

// Invalid if at least one locale is invalid
const invalid = computed(() => (
  !isNil(dynamicLocales.value.find((locale) => (
    isInvalid(locale)
  )))
))

function isInvalid(locale) {
  return get(form.value?.errors, nameWithLocale(locale))?.length > 0
}

function handleLocaleChange(value) {
  selectedLocale.value = value
}

function rulesToUse(localeToValidate) {
  let rules = props.formGroupProps?.rules

  if (props.requiredLocales.includes(localeToValidate)) {
    rules = [rules, 'required'].join('|')
  }

  return rules
}

function nameWithLocale(locale) {
  return `${props.formGroupProps?.name}.${locale}`
}

function formGroupPropByLocale(locale, propName, ...parameters) {
  // Call form group prop function, with the locale parameter appended
  if (typeof props.formGroupProps[propName] === 'function') {
    return props.formGroupProps[propName](...parameters, locale)
  }

  // If prop is not a function, return the original prop
  return props.formGroupProps[propName]
}

function formControlPropByLocale(locale, propName, ...parameters) {
  // Call form control prop function, with the locale parameter appended
  if (typeof props.formGroupProps.formControlProps[propName] === 'function') {
    return props.formGroupProps.formControlProps[propName](...parameters, locale)
  }

  // If prop is not a function, return the original prop
  return props.formGroupProps.formControlProps[propName]
}
</script>
