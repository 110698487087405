import { ref } from 'vue'
import { useStore } from 'vuex'
import { isNil, uniqBy } from 'lodash'

import { fetchTags } from '@shared/http/api'
import useLocale from '@shared/hooks/locale'

export default function useTags() {
  const store = useStore()
  const { getAttributeTranslation } = useLocale()

  // ---------- TAGS ----------

  const initialTagsSearch = ref(true)
  const tags = ref([])

  // Return tags
  function formatTagsOptions(tags) {
    return tags.map((tagResource) => ({
      label: getAttributeTranslation(tagResource.attributes?.label),
      value: tagResource.id,
    }))
  }

  function handleTagsSearch(searchKeywords) {
    return new Promise((resolve) => {
      let tagOptions = []
      const params = {}

      if (initialTagsSearch.value) {
        initialTagsSearch.value = false

        if (store.state.filters.pointsOfInterest.tags_ids) {
          // Provide an option with the resource's relationship value
          tagOptions.push(formatTagsOptions(store.state.filters.pointsOfInterest.tags_ids))
        }
      }

      // Search keywords
      if (!isNil(searchKeywords)) {
        params.search = searchKeywords
      }

      // Filter by category
      if (!isNil(store.state.filters.pointsOfInterest.point_of_interest_category_id)) {
        params.categoryId = store.state.filters.pointsOfInterest.point_of_interest_category_id
      }

      fetchTags(params)
        .then((e) => {
          // get the new options from the response
          tagOptions = tagOptions.concat(formatTagsOptions(e.data.data))

          // Avoid updating var after each select change
          if (tags.value.length <= 0) {
            tags.value = e.data.data
          }
        })
        .finally(() => {
          resolve(uniqBy(tagOptions, 'value'))
        })
    })
  }

  return {
    initialTagsSearch,
    tags,
    handleTagsSearch,
  }
}
