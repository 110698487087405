<template>
  <app-button
    feature="help"
    :hide-icon="false"
    :label="t('common.actions.help')"
    color="theme"
    emphasis="low"
    @click="handleHelpShow"
  />

  <!-- Help modal -->
  <app-overlay
    :show="helpModalDisplayed"
    @clicked="handleHelpHide"
  >
    <app-modal
      text-align-classes="text-left"
      @closed="handleHelpHide"
    >
      <app-tutorial-layout
        :tutorials="tutorials"
      />
    </app-modal>
  </app-overlay>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

import AppButton from '@shared/components/ui/AppButton.vue'
import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppTutorialLayout from '@extranet/components/ui/help/AppTutorialLayout.vue'

const { t } = useI18n()

defineProps({
  // Define tutorials
  tutorials: {
    type: Array,
    default: () => ([]),
  },
})

// ---------- HELP ----------

const helpModalDisplayed = ref(false)

function handleHelpShow() {
  helpModalDisplayed.value = true
}

function handleHelpHide() {
  helpModalDisplayed.value = false
}
</script>
