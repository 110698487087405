import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import {
  isNil,
  round,
  uniq,
  uniqBy,
} from 'lodash'
import { fromJS } from 'immutable'

import { geocoding } from '@shared/http/mapbox'

export default function useGeolocFilters(form = null) {
  const store = useStore()
  const { t } = useI18n()

  // https://docs.mapbox.com/api/search/geocoding/#data-types
  const mapboxTypesFilter = [
    'country',
    'region',
    'postcode',
    'district',
    'place',
    'locality',
    'neighborhood',
    'address',
    'poi',
  ]

  // ---------- FILTER OPTIONS ----------

  const filterOptions = ref([])

  function handleGeolocFilterSearch(searchKeywords) {
    return new Promise((resolve) => {
      filterOptions.value = []

      if (searchKeywords?.length > 0) {
        geocoding(searchKeywords, {
          types: mapboxTypesFilter.join(','),
          language: store.state.auth.user.attributes.locale,
        })
          .then((r) => {
            const apiOptions = r.data.features
              ?.filter((place) => (
                // Check if name, latitude & longitude
                // are presents
                place.place_name
                && place.text
                && place.center?.[0]
                && place.center?.[1]
              ))
              ?.map((place) => {
                // Build option's label
                let label = ''
                // Specify address in the label when available
                if (place.place_type.includes('address') && place.address) {
                  label = `${place.address} ${place.text}`
                } else if (place.place_type.includes('poi') && place.properties?.address) {
                  label = `${place.text}, ${place.properties.address}`
                } else {
                  label = place.text
                }

                let icon

                if (place.place_type.includes('country')) {
                  icon = 'globe'
                } else if (place.place_type.includes('region')) {
                  icon = 'map'
                } else if (place.place_type.includes('district')) {
                  icon = 'map'
                } else if (place.place_type.includes('postcode')) {
                  icon = 'map-marked-alt'
                } else if (place.place_type.includes('place')) {
                  icon = 'map-marked-alt'
                } else if (place.place_type.includes('neighborhood')) {
                  icon = 'map-signs'
                } else if (place.place_type.includes('locality')) {
                  icon = 'map-signs'
                }
                if (place.place_type.includes('poi')) {
                  icon = 'map-marker-alt'
                } else if (place.place_type.includes('address')) {
                  icon = 'map-signs'
                }

                return {
                  value: place.place_name,
                  label,
                  subLabel: uniq(place.context?.map((c) => c.text)).join(', '),
                  context: place.context,
                  coords: {
                    latitude: place.center[1],
                    longitude: place.center[0],
                  },
                  icon,
                }
              })

            filterOptions.value = filterOptions.value.concat(apiOptions)
          })
          .finally(() => {
            resolve(completeFilterOptions.value)
          })
      } else {
        resolve(completeFilterOptions.value)
      }
    })
  }

  function handleGeolocFilterChange(value) {
    const option = completeFilterOptions.value.find((filter) => (
      filter.value === value
    ))

    if (option?.coords && !isNil(form)) {
      // Apply new latitude and longitude
      // when a new filter is selected
      form.setFieldValue(
        'latitude',
        option.coords.latitude,
      )

      form.setFieldValue(
        'longitude',
        option.coords.longitude,
      )
    } else if (isNil(option?.coords) && !isNil(form)) {
      // Unset latitude and longitude
      // If there is no option or no option's coords
      form.setFieldValue(
        'latitude',
        null,
      )

      form.setFieldValue(
        'longitude',
        null,
      )
    }

    // If a geoloc's value is provided, and that there was no
    // geoloc's value in the current store's state:
    // Enable limit_to_a_radius by default
    if (value && !store.state.filters.pointsOfInterest.geoloc) {
      form.setFieldValue('limit_to_a_radius', true)
    }
  }

  // Build an option from state's default geoloc filter
  const defaultGeolocFilterOption = computed(() => (
    store.state.filters.default.pointsOfInterest.geoloc
    && store.state.filters.default.pointsOfInterest.latitude
    && store.state.filters.default.pointsOfInterest.longitude
      ? {
        label: store.state.filters.default.pointsOfInterest.geoloc,
        value: store.state.filters.default.pointsOfInterest.geoloc,
        coords: {
          latitude: store.state.filters.default.pointsOfInterest.latitude,
          longitude: store.state.filters.default.pointsOfInterest.longitude,
        },
        icon: null,
      }
      : null
  ))

  // Build an option from state's current geoloc filter
  const currentGeolocFilterOption = computed(() => (
    store.state.filters.pointsOfInterest.geoloc
    && store.state.filters.pointsOfInterest.latitude
    && store.state.filters.pointsOfInterest.longitude
      ? {
        label: store.state.filters.pointsOfInterest.geoloc,
        value: store.state.filters.pointsOfInterest.geoloc,
        coords: {
          latitude: store.state.filters.pointsOfInterest.latitude,
          longitude: store.state.filters.pointsOfInterest.longitude,
        },
        icon: null,
      }
      : null
  ))

  const completeFilterOptions = computed(() => {
    const filterOptionsCopy = fromJS(filterOptions.value).toJS()

    // Keep current filter
    if (currentGeolocFilterOption.value) {
      filterOptionsCopy.push(currentGeolocFilterOption.value)
    }

    // Keep default filter as last option
    if (defaultGeolocFilterOption.value) {
      filterOptionsCopy.push(defaultGeolocFilterOption.value)
    }

    return uniqBy(filterOptionsCopy, 'value')
  })

  // ---------- RADIUS ----------

  const distanceCoeff = 1.609

  const measurementSystemValue = computed(() => (
    form.values?.measurement_system
  ))

  // Increase max radius for metric measurement
  const maxRadius = computed(() => (
    measurementSystemValue.value === 'metric'
      ? 50
      : 50 / distanceCoeff
  ))

  function formatSliderTooltips(value) {
    const suffix = measurementSystemValue.value === 'metric'
      ? t('common.units.km')
      : t('common.units.mi')

    return `${round(value, 0)} ${suffix}`
  }

  watch(measurementSystemValue, (newValue, oldValue) => {
    const radiusValue = form.values?.radius
    let newRadiusValue = null

    // Convert radius value from/to metric/imperial measurement
    if (radiusValue) {
      if (newValue === 'imperial' && oldValue === 'metric') {
        newRadiusValue = radiusValue / distanceCoeff
      } else if (newValue === 'metric' && oldValue === 'imperial') {
        newRadiusValue = radiusValue * distanceCoeff
      }

      if (newRadiusValue) {
        newRadiusValue = round(newRadiusValue, 2)

        form.setFieldValue(
          'radius',
          newRadiusValue,
        )
      }
    }
  })

  return {
    maxRadius,
    handleGeolocFilterChange,
    handleGeolocFilterSearch,
    formatSliderTooltips,
  }
}
