<template>
  <div>
    <div
      class="flex flex-col md:flex-row flex-wrap
      justify-end items-start md:items-center
      space-y-2 md:space-y-0 md:space-x-2"
    >
      <share-action
        :resource="resource"
      />

      <app-button
        v-if="resource.authorizations.update"
        feature="edit"
        @click="router.push({
          name: 'edit-excursions',
          params: { id: resource.id },
        })"
      />

      <app-button
        v-if="resource.authorizations.delete"
        feature="delete"
        @click="handleDestroy"
      />
    </div>

    <!-- Cancel modal -->
    <app-overlay
      :show="deleteConfirmModalDisplayed"
      @clicked="handleDestroyCancel"
    >
      <app-modal @closed="handleDestroyCancel">
        <app-confirm
          @cancelled="handleDestroyCancel"
          @confirmed="handleDestroyConfirm"
        >
          <p>
            {{
              t(
                'excursions.destroy.confirm',
                { excursion_name: getAttributeTranslation(resource.attributes.title) }
              )
            }}
          </p>
        </app-confirm>
      </app-modal>
    </app-overlay>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import AppOverlay from '@shared/components/ui/AppOverlay.vue'
import AppModal from '@shared/components/ui/AppModal.vue'
import AppConfirm from '@shared/components/ui/AppConfirm.vue'
import AppButton from '@shared/components/ui/AppButton.vue'
import useLocale from '@shared/hooks/locale'
import ShareAction from '@extranet/components/resources/excursion/actions/ShareAction.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Function called on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
  // Display texts
  showLabels: {
    type: Boolean,
    default: false,
  },
})

const emits = defineEmits([
  'destroyed',
])

const router = useRouter()
const { t } = useI18n()
const { getAttributeTranslation } = useLocale()

const deleteConfirmModalDisplayed = ref(false)

function handleDestroy() {
  deleteConfirmModalDisplayed.value = true
}

function handleDestroyConfirm() {
  deleteConfirmModalDisplayed.value = false
  emits('destroyed')
  props.destroyCallback()
}

function handleDestroyCancel() {
  deleteConfirmModalDisplayed.value = false
}
</script>
