import { computed, ref } from 'vue'
import { isNull } from 'lodash'

export default function useShareForm() {
  const mode = ref(null)

  const isUnselectedMode = computed(() => (
    isNull(mode.value)
  ))

  const isEmailMode = computed(() => (
    mode.value === 'email'
  ))

  const isEmailOrUnselectedMode = computed(() => (
    isEmailMode.value
      || isUnselectedMode.value
  ))

  const isRecipientsMode = computed(() => (
    mode.value === 'recipients'
  ))

  const isRecipientsOrUnselectedMode = computed(() => (
    isRecipientsMode.value
      || isUnselectedMode.value
  ))

  function handleRecipientsChange(value) {
    mode.value = value.length > 0
      ? 'recipients'
      : null
  }

  function handleEmailChange(value) {
    mode.value = value.length > 0
      ? 'email'
      : null
  }

  return {
    mode,
    isUnselectedMode,
    isEmailMode,
    isEmailOrUnselectedMode,
    isRecipientsMode,
    isRecipientsOrUnselectedMode,
    handleRecipientsChange,
    handleEmailChange,
  }
}
