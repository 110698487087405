<template>
  <router-link
    :to="{ name: 'show-excursions', params: { id: resource.id } }"
    class="flex items-center cursor-pointer"
    :title="l10nTitle"
  >
    <div class="mr-2">
      <app-rounded-picture
        :picture-url="getMainImageUrl('xs')"
        picture-size="8"
        icon-name="taxi"
        icon-size="xs"
      />
    </div>

    <div class="flex justify-between w-full">
      <span class="truncate">
        {{ title }}
      </span>

      <excursion-icon
        :resource="resource"
      />
    </div>
  </router-link>
</template>

<script setup>
import { computed } from 'vue'
import { truncate } from 'lodash'

import useExcursion from '@shared/hooks/resources/excursion'
import AppRoundedPicture from '@shared/components/ui/AppRoundedPicture.vue'
import ExcursionIcon from '@shared/components/resources/excursion/ExcursionIcon.vue'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const {
  getMainImageUrl,
  l10nTitle,
} = useExcursion(props)

const title = computed(() => (
  truncate(l10nTitle.value, {
    length: 35,
    separator: ' ',
  })
))
</script>
