<template>
  <div class="flex flex-col w-full">
    <p
      class="text-lg font-bold flex items-center mb-4 cursor-pointer w-fit"
      @click="toggleFiltersDisplayed"
    >
      {{ filtersLabel }}

      <font-awesome-icon
        :icon="filtersArrow"
        class="ml-5"
      />
    </p>

    <transition
      appear
      @enter="hideFiltersOverflow"
      @after-enter="showFiltersOverflow"
      @leave="hideFiltersOverflow"
    >
      <div
        v-show="filtersDisplayed"
        :style="`${filtersDisplayed ? `height: ${filtersHeight}px;` : ''}`"
        :class="`h-0 duration-300 ${filtersOverflowHidden ? 'overflow-hidden' : ''}`"
      >
        <div
          ref="filtersWrapper"
        >
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import {
  computed,
  onMounted,
  ref,
  onBeforeUnmount,
} from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  // Display filters on loading
  displayFilters: {
    type: Boolean,
    default: false,
  },
})

const { t } = useI18n()

const filtersWrapper = ref() // Template ref
const filtersHeight = ref(0) // Filters' DOM element's height need to be set, to allow animation with transition

const filtersDisplayed = ref(props.displayFilters)

const filtersLabel = computed(() => (
  filtersDisplayed.value
    ? t('extranet.filters.closed')
    : t('extranet.filters.open')
))

const filtersArrow = computed(() => (
  filtersDisplayed.value
    ? 'chevron-up'
    : 'chevron-down'
))

// Hide or not the filter's overflow.
// Overflow must not be displayed during filter's transition
const filtersOverflowHidden = ref(filtersDisplayed.value)

function toggleFiltersDisplayed() {
  filtersDisplayed.value = !filtersDisplayed.value
}

// Adapt filters height
function adaptFiltersHeight(height) {
  filtersHeight.value = height
}

function hideFiltersOverflow() {
  filtersOverflowHidden.value = true
}

function showFiltersOverflow() {
  filtersOverflowHidden.value = false
}

// Observe filters' wrapper height, then adapt it
const resizeObserver = new ResizeObserver((entries) => {
  adaptFiltersHeight(entries[0].contentRect.height)
})

onMounted(() => {
  resizeObserver.observe(filtersWrapper.value)
})

onBeforeUnmount(() => {
  resizeObserver.unobserve(filtersWrapper.value)
})
</script>
