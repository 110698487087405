<template>
  <div class="flex">
    <!-- Menu -->
    <div
      v-show="displayMenu"
      class="w-1/2 border-r-1"
    >
      <ul class="my-4 px-4">
        <li
          v-for="(tutorial, i) in tutorials"
          :key="i"
          :class="menuItemStyle(tutorial.menuItem)"
          @click="changeSelection(tutorial)"
        >
          {{ tutorial.menuItem }}
        </li>
      </ul>
    </div>

    <!-- Content -->
    <div class="w-full h-full mx-4 mb-4">
      <component
        :is="selected.component"
        v-if="selected?.component"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, shallowRef } from 'vue'

const props = defineProps({
  // Define tutorials
  tutorials: {
    type: Array,
    default: () => ([]),
  },
})

// ---------- SELECTED ----------

const selected = shallowRef({})

function changeSelection(value) {
  selected.value = value
}

// ---------- MENU ----------

const displayMenu = computed(() => (
  props.tutorials.length > 1
))

function menuItemStyle(value) {
  const classes = [
    'hover:text-theme-300',
    'font-semibold',
    'cursor-pointer',
    'mb-6',
  ]

  classes.push(
    selected.value?.menuItem === value
      ? 'text-theme-500'
      : 'text-theme-900',
  )

  return classes.join(' ')
}

// ---------- LIFECYCLES ----------

onMounted(() => {
  changeSelection(props.tutorials[0])
})
</script>
