export default function usePdf() {
  /**
   * Execute PDF file download
   *
   * @param data
   * @param filename
   */
  function downloadPdf(data, filename) {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    const pdfBlob = new Blob([data], { type: 'application/pdf' })

    // Create a link pointing to the ObjectURL containing the blob.
    const pdfData = window.URL.createObjectURL(pdfBlob)
    const link = document.createElement('a')
    link.href = pdfData
    link.download = `${filename}-${new Date().getTime()}.pdf`
    link.click()
    link.remove()

    // For Firefox, it is necessary to delay revoking the ObjectURL
    setTimeout(() => {
      window.URL.revokeObjectURL(pdfData)
    }, 100)
  }

  return {
    downloadPdf,
  }
}
