<template>
  <div class="p-3">
    <router-link
      v-slot="{ navigate }"
      custom
      :to="{ name: 'show-excursions', params: { id: resource.id } }"
    >
      <div
        :class="`block truncate text-base font-bold text-left ${withLink ? 'cursor-pointer' : ''}`"
        @click="withLink ? navigate() : null"
      >
        {{ l10nTitle }}
      </div>
    </router-link>
  </div>
</template>

<script setup>
import useExcursion from '@shared/hooks/resources/excursion'

const props = defineProps({
  // JSON API resource
  resource: {
    type: Object,
    default: () => ({}),
  },
  // function called on delete handling
  destroyCallback: {
    type: Function,
    default: () => ({}),
  },
  // hide actions
  hideActions: {
    type: Boolean,
    default: false,
  },
  // Add a link to resource show page or not
  withLink: {
    type: Boolean,
    default: true,
  },
})

const { l10nTitle } = useExcursion(props)
</script>
