<template>
  <div>
    <form-translated
      :form-group-props="{
        name: 'title',
        formLabelProps: {
          required: true,
        },
      }"
    />

    <div class="flex justify-start">
      <form-group
        name="kind"
        type="radio"
        :form-control-props="{
          options: kindOptions,
        }"
      >
        <template #help-button>
          <ul>
            <li class="mb-5">
              <p class="font-bold">
                {{ t('enums.App\\Enums\\Excursion\\KindEnum.customized') }}
              </p>

              {{ t('form.hints.excursion.kind.pro.customized') }}
            </li>

            <li class="mb-5">
              <p class="font-bold">
                {{ t('enums.App\\Enums\\Excursion\\KindEnum.root') }}
              </p>

              {{ t('form.hints.excursion.kind.pro.root') }}
            </li>

            <li
              v-if="dashboardTouristOfficeEmployeeAccess"
            >
              <p class="font-bold">
                {{ t('enums.App\\Enums\\Excursion\\KindEnum.tourist_office') }}
              </p>

              {{ t('form.hints.excursion.kind.pro.tourist_office') }}
            </li>
          </ul>
        </template>
      </form-group>

      <form-group
        :initial-value="resource.attributes?.favorite"
        name="favorite"
        :label="t('validation.attributes.favorite')"
        type="switch"
        class="mt-1 ml-6"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import useAccess from '@extranet/hooks/access'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import FormGroup from '@shared/components/form/FormGroup.vue'
import FormTranslated from '@shared/components/form/FormTranslated.vue'

defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
})

const { t } = useI18n()

// ---------- ACCESS ----------

const {
  dashboardTouristOfficeEmployeeAccess,
} = useAccess()

// ---------- KIND ----------

const {
  excursionKindOptions,
  excursionKindToOptions,
} = useSelectOptions()

const kindOptions = computed(() => (
  dashboardTouristOfficeEmployeeAccess.value
    ? excursionKindToOptions
    : excursionKindOptions
))
</script>
